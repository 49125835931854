import Vue from 'vue'
import App from './App.vue'

//* SETUP
import * as CONFIG from '@/setup/init.js'
import router from '@/setup/router.js'
import store from '@/setup/store.js'

//* CSS
import '@/scss/main.scss'

//* Vee-Validate
import '@/setup/vee-validate.js'

//* Bootstrap
//import bootstrap from "bootstrap/dist/js/bootstrap.js"
//import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js "

//* PLUGINS
import CookieBanner from '@/plugins/cookiebanner/cookie-banner.js'
import VueCookies from 'vue-cookies'

//* MIXINS
import apiH from '@/mixins/apiHandler.js'
import globalMI from '@/mixins/globalMI.js'

//* axios
import axios from 'axios'
Vue.prototype.$axios = axios
Vue.prototype.$api_conf = CONFIG.api_conf;
Vue.prototype.$bootstrap = require('bootstrap');
//Vue.prototype.$bootstrap = bootstrap

//* Import FontAwsome
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

library.add(fas)

Vue.component('font-awesome-icon', FontAwesomeIcon)

//* Global components
import Preloader from '@/components/Preloader.vue'
Vue.component('Preloader', Preloader)

//* Scrollto 
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)

//* PLUGINS
Vue.use(VueCookies)
Vue.use(CookieBanner)

//* You can also pass in the default options
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

//* MIXINS
Vue.mixin(apiH);
Vue.mixin(globalMI);

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
