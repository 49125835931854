import { required, email, confirmed, length, integer, size, ext, regex } from "vee-validate/dist/rules"
import { extend } from "vee-validate"
import { setInteractionMode } from 'vee-validate'

setInteractionMode('lazy')

extend("email", {
    ...email,
    message: "A megadott e-mail cím érvénytelen, a formázás ellenőrzése szükséges (pl. email@domain.com)."
  })

extend("required", {
  ...required,
  message: "Kötelező mező"
})

extend("confirmed", {
  ...confirmed,
  message: "Nem egyező értékek"
})

extend("integer", {
  ...integer,
  message: "Csak számok adhatók meg"
})


extend("length", {
  ...length,
  message: "Nem megfelelő hossz"
})

extend("min", {  
  validate(value, args) {
    return value.length >= args.length
  },
  params: ["length"],
  message: "Legalább {length} karaktert kell tartalmaznia"
})

extend("max", {  
  validate(value, args) {
    return value.length <= args.length
  },
  params: ["length"],
  message: "Legfeljebb {length} karaktert tartalmazhat"
})

extend("size", {
  ...size,
  message: "Max 5MB lehet a fájl mérete"
})

extend("ext", {
  ...ext,
  message: "Csak png vagy jpeg formátum tölthető fel"
})


extend("regex", {
  ...regex,
  message: "Nem megfelelő karakterek"
})

extend("acceptConditions", {
  validate(value) {
    if (parseInt(value) === 1) {
      return true;
    } else {
      return false;
    }
  },
  message: "Kötelező mező"
});

extend("customRegex", {
  validate(value) {
    var reg = new RegExp('[0-9]');
    console.log(reg.test(value))
    if (reg.test(value)){
      return false
    } else {
      return true
    }    
  },
  message: "Nem megfelelő karakterek"
});